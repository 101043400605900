import React, { FC, ReactNode } from 'react'
import { CSSProperties } from 'styled-components'

interface ISectionProps {
  id?: string
  children: ReactNode[] | ReactNode
  className?: string
  style?: CSSProperties
  isNoMargin?: boolean
}

const Section: FC<ISectionProps> = ({
  children,
  id,
  style,
  className,
  isNoMargin,
}) => (
  <section
    className={`${className} ${isNoMargin ? '' : 'mb-24'}`}
    id={id || ''}
    style={style}
  >
    {children}
  </section>
)

export default Section
